<div class="row" style="gap: 0px 15px">
 
  <div    class="col-xl-5 col-md-4 col-sm-6 mb-50"    style="  background-color: #fff;   box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; border-radius: 4px;">
   
  <button type="button" class="btn btn-primary"   (click)="dialogRef.close()">Close</button>
    <div
      class=""
      style="overflow-y: auto; max-height: 300px; font-size: 9px !important"  *ngIf="stdSection"
    >
      <div class="card">
        <div class="d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">Daily Un Attended Classes</span>
        </div>

        <div class="mt-3" *ngFor="let section of stdSection">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row align-items-center">
              <span class="star"><i class="fa fa-star yellow"></i></span>
              <div class="d-flex flex-column">
                <span style="font-size: 12px !important">{{
                  section.class_section
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </div>

  <div
    class="col-xl-4 col-md-4 col-sm-6 mb-50"
    style="
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 4px;
    "
    *ngIf="absentCounts"
  >
    <div
      class=""
      style="overflow-y: auto; max-height: 300px; font-size: 9px !important"
    >
      <div class="card">
        <div class="d-flex justify-content-between align-items-center">
          <span class="font-weight-bold">Daily Most Absent Classes</span>
        </div>

        <div class="mt-3" *ngFor="let absent of absentCounts">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex flex-row align-items-center">
              <span class="star"><i class="fa fa-star yellow"></i></span>
              <div class="d-flex flex-column">
                <span style="font-size: 9px !important"
                  >{{ absent.class_name }} - {{ absent.section_name }}</span
                >
              </div>
            </div>
            <span class="content-text-1" style="font-size: 9px !important">{{
              absent.absent_students_count
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</div>

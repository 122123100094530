import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError,finalize, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "../services/toastr.service";
import { LoaderService } from "./http-loader.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth:AuthService, private loaderService: LoaderService, private router:Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let credentials: any = this.auth.getCredential();
    if (!credentials) {
      credentials = {
        token: ""
      }
    }
    const cloneRquest: any = {
      headers: new HttpHeaders({
        'Authorization': "bearer " + credentials.token
      })}

       //prevent duplicate
    if (this.loaderService.isWaiting && ['POST', 'PUT', "DELETE"].includes(req.method)) {
      throw new Error("Please slow down!...");
    }
    const isLoading = ['POST', 'PUT', "DELETE"].includes(req.method)
    if (isLoading)
      this.loaderService.show("Taking action");
    const authReq = req.clone(cloneRquest);
    return next.handle(authReq).pipe(
      catchError(err => {
        let errMsg = err.error || err.error.message || err.error.error
        if (err.status === 0) {
          errMsg = "Enable to connect server"
        }

        if (err.status === 401) {
          this.router.navigate(['users', 'login'])
        }

        if (err.status === 500) {
        }
        if (typeof errMsg == 'string')
        errMsg = errMsg.replace("ER_DUP_ENTRY:", "")
      return throwError({ message: errMsg });
    }),
      finalize(() => this.loaderService.hide())
    );
  }
}

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private inj: Injector, private toastr: ToastrService, private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {
        let errMsg = err.error || err.error.message || err.error.error
        if (err.status === 0) {
          errMsg = "Enable to connect server"
        }

        if (err.status === 401) {
          this.router.navigate(['users', 'login'])
        }

        if (err.status === 500) {
        }
        return throwError({ message: errMsg });
      })
    );
  }
}

export const authInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: UnauthorizedInterceptor,
    multi: true,
  },
];

export enum Statuses {
  CANCELLED = 'Cancelled',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted',
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
}
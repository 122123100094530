import { Pipe, PipeTransform } from '@angular/core';
import { Statuses } from '../enum/statuses';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(value: Statuses, capitalize?: boolean): string {
    let status = ''
    switch (value) {
      case Statuses.ENABLED:
        status = 'All Status';
        break
      case Statuses.ACTIVE:
        status = 'Active';
        break
      default:
        status = 'All Status';
        break
    }

    status = capitalize ? status.toUpperCase() : status
    return status
  }

}

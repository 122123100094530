import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    isWaiting = false
    isLoading = new Subject<boolean>();
    show(data) {
        this.isLoading.next(data);
        this.isWaiting = true
    }
    hide() {
        this.isWaiting = false
        this.isLoading.next(false);
    }
}
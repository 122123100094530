<h1 mat-dialog-title>Change Password</h1>
<form [formGroup]="formGroup" (submit)="submitForm()">
    <div mat-dialog-content>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Old Password</mat-label>
                    <input matInput formControlName="old_password" type="password" required>
                    <mat-error>
                        <control-messages [control]="formGroup.get('old_password')">
                        </control-messages>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Password</mat-label>
                    <input matInput formControlName="password" type="password" required>
                    <mat-error>
                        <control-messages [control]="formGroup.get('password')">
                        </control-messages>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Confirm Password</mat-label>
                    <input matInput formControlName="confirm_password" type="password" required>
                    <mat-error>
                        <control-messages [control]="formGroup.get('confirm_password')">
                        </control-messages>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <button mat-raised-button color="accent" [mat-dialog-close]="false">Cancel</button>
        </div>
        <div class="col-md-6">
            <button mat-raised-button small color="primary" [disabled]="formGroup.invalid">Save</button>
        </div>
    </div>
</form>
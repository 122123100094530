import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; 
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {MatNativeDateModule} from '@angular/material/core';

import { AppComponent } from './app.component';

import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { FixedpluginModule} from './shared/fixedplugin/fixedplugin.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

import { AppRoutes } from './app.routing';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { LoaderService } from './core/http/http-loader.service';
import { HttpService } from './core/http/http.service';
import { AuthService } from './core/services/auth.service';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/http/http.interceptor';
import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
    imports:      [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes,{
          useHash: true
        }),
        HttpClientModule,
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedpluginModule,
        SharedModule,
        
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        NotFoundComponent,
    ],
    providers : [
      MatNativeDateModule,
      LoaderService,
      AuthService,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      HttpService,
      AuthService,
    ],
    bootstrap:    [ AppComponent ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class AppModule { }

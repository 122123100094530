<div class="container mt-5">
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-8">
            <img src="./assets/img/system/404.png" class="w-100" alt="">
        </div>
        <div class="col-12">
            <button class="btn btn-secondary" style="display:block;margin:auto;" [routerLink]="['/']">Back to
                Dashboard
            </button>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class AttendenceService {
  baseUrl = '/dash/attendences'
  constructor(private http: HttpService) { }

  getAttendences() {
    return this.http.list(this.baseUrl, {});
  }
  getAttendence(data: any) {
    return this.http.list(this.baseUrl + '/' + data.id, data);
  }
  postAttendence(data) {
    return this.http.post(this.baseUrl, data);
  }
  CustomAttendence(data) {
    return this.http.post(this.baseUrl + '/custom', data);
  }
  putCustomAttendence(data) {
    return this.http.update(this.baseUrl + '/custom/', data);
  }
  putAttendence(data, id) {
    return this.http.update(this.baseUrl + '/' + id, data);
  }
  arrangeClass(data) {
    return this.http.post(this.baseUrl + '/arrange', data);
  }
  dltAttendence(id) {
    return this.http.delete(this.baseUrl + '/' + id, id);
  }
  getStudSection(data) {
    return this.http.post(this.baseUrl + '/query', data);
  }
  getAbsentStudents(data) {
    return this.http.post(this.baseUrl + '/absents', data);
  }
  getAttendenceReport(data) {
    return this.http.post(this.baseUrl + '/summary-report', data);
  }
  getIndividualAttendanceReport(data) {
    return this.http.post(this.baseUrl + '/individual-attendance', data);
  }
  getAttendenceDashboard() {
    return this.http.post(this.baseUrl + '/dashboard-summary', {});
  }
  getStudentAttendenceReport(id: any) {
    return this.http.list(this.baseUrl + '/student-attedence-report', {
      params:  id 
    });
  }
}

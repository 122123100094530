import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { AutoCompleteComponent } from './components/auto-complete/auto-complete.component';
import { PrintLayoutComponent } from '../layouts/print-layout/print-layout.component';
import { StatusPipe } from './pipes/status.pipe';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import { ControlMessagesComponent } from './components/control-messages/control-messages.component';
import { LoadingComponent } from './components/loading/loading.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [
    SpinnerComponent,
    PopupMenuComponent,
    AutoCompleteComponent,
    PrintLayoutComponent,
    ShowErrorsComponent,
    ControlMessagesComponent,
    LoadingComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    SpinnerComponent,
    PopupMenuComponent,
    AutoCompleteComponent,
    PrintLayoutComponent,
    ShowErrorsComponent,
    ControlMessagesComponent,
    LoadingComponent,  
  ],
    providers:[StatusPipe,CurrencyPipe,DatePipe]
})
export class SharedModule { }

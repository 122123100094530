import { Injectable, Input, NgModule } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpService } from '../http/http.service';
import { API_ROUTES } from '../http/api-routes';


export enum Roles {
  // Marks
  UPDATE_MARKS = 'update-marks',
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  tokenKey = 'pfms_cred';
  isAuthenticated: Boolean = false;
  username: Subject<string> = new Subject<string>();
  private userClasses = new BehaviorSubject<Array<number>>(null);
  private userRoles = new BehaviorSubject<Array<string>>(null);
  userUrl: Subject<string> = new Subject<string>();
  authToken: string = undefined;
  _user_data: any
  constructor(private http: HttpService, private router: Router) {
    this.loadUserCredentials()
  }
  getUser() {
    let { user } = this.getCredential()
    return user
  }

  findUserClasses(id: number) {
    return this.http.list(API_ROUTES.LIST_USER_CLASSES, {id}).toPromise()
  }

  hasPermission(roles: String): boolean {
    const { modules } = this.user_data;
    // if user have no modules
    if (!modules) return false

    // 
    const flattenedPaths = modules.reduce((acc, item) => {
      acc.push(item.path);
      if (item.children) {
        acc = acc.concat(item.children.map(child => child.path));
      }
      return acc;
    }, []);

    if (this.isAdmin) return true;

    return flattenedPaths.includes(roles);
  }

  checkJWTtoken() {
    return this.http.list('/auth', {})
      .pipe(map((res: any) => {
        this.sendUsername(res.username)
        this.userClasses.next(res.classes)
        this.sendUserUrl(res.url)
        return res
      }));
  }

  get user_data(): any {
    return this._user_data;
  }
  set user_data(val: any) {
    this._user_data = val;
  }

  sendUsername(name: string) {
    this.username.next(name);
  }

  sendUserUrl(name: string) {
    this.userUrl.next(name);
  }

  clearUserUrl() {
    this.userUrl.next(undefined);
  }
  clearUsername() {
    this.username.next(undefined);
  }

  loadUserCredentials() {
    const credentials = this.getCredential()
    if (credentials && credentials.username !== undefined) {
      this.useCredentials(credentials);
    }
  }

  storeUserCredentials(credentials: any) {
    this.tokenKey = 'pfms_cred'
    localStorage.setItem(this.tokenKey, JSON.stringify(credentials));
    this.useCredentials(credentials);
  }

  get isAdmin(): boolean {
    return this.getUser().type === "100"
  }

  useCredentials(credentials: any) {
    this.isAuthenticated = true;
    this.sendUsername(credentials.username);
    this.sendUserUrl(credentials.url);
    this.authToken = credentials.token;

  }

  destroyUserCredentials() {
    this.authToken = undefined;
    this.clearUsername();
    this.clearUserUrl();
    this.isAuthenticated = false;
    localStorage.removeItem(this.tokenKey);
    localStorage.clear();
  }

  signUp() {

  }

  logIn(user: any): Observable<any> {
    return this.http.post("/auth/signin", user)
      .pipe(map((res: any) => {

        this.storeUserCredentials({ token: res.token, user: res.user });
        return res
      }));
  }

  logOut() {
    this.destroyUserCredentials();
  }

  isLoggedIn(): Boolean {
    return this.isAuthenticated;
  }

  getUsername(): Observable<string> {
    return this.username.asObservable();
  }

  getUserUrl(): Observable<string> {
    return this.userUrl.asObservable();
  }

  getClasses(): Array<number> {
    return this.userClasses.value;
  }
  getToken(): string {
    return this.authToken;
  }

  getCredential() {
    return JSON.parse(localStorage.getItem('pfms_cred'))
  }

  navigateUser(role: number): Array<string> {
    if (role == 777) {
      return ['admin']
    }

    if (role == 776) {
      return ['users']
    }

  }
}
